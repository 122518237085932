<template>
  <div class="job-details">
    <!-- Loading -->
    <div v-if="loading && !error" class="container loading">
      <b-card>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </b-card>
    </div>

    <!-- Page Error -->
    <div v-if="error" class="container error mt-5">
      <b-alert show dismissible variant="danger">
        <span v-html="error"></span>
      </b-alert>
      <a href="#" @click="$router.go(-1)">Go back</a>
    </div>

    <!-- JOB LOADED -->
    <div v-if="!loading && job" class="content">
      <ClaimCreate :order_id="job.id" />

      <!-- grey intro -->
      <div class="bg-site-light pt-4 mb-4">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div
                class="
                  d-flex
                  flex-column flex-sm-row
                  justify-content-sm-between
                  align-items-sm-end
                  py-2
                  text-center text-sm-left
                "
              >
                <div class="flex-sm-fill">
                  <h2 class="mb-0">
                    <span class="text-secondary"> Create Claim </span>
                  </h2>
                  <h5 class="mr-auto">{{ job.jobid }} / {{ job.name }}</h5>
                </div>
               
              </div>
            </div>
          </div>
        </div>
 
           
      </div>
      

      <!-- Grid Wrapper / Layout -->

      <div class="container">
        <div class="row">
          <div class="col-md-8 bg-site-light py-4 rounded">
            <div class="details-wrapper">
              <!-- GRID-AREA: INTRO -->
              <div class="details-intro">
                <!-- JOB NOTICE -->
                <div class="row">
                  <div class="col-md-12 col-sm-12">
                    <b-card-body class="pt-3">
                      <h5>Create new claim</h5>
                      <p>
                        You are about to begin the process of creating a new
                        claim.<br />Alfredssons will take care of the claims
                        processing and will send a replacement or issue a
                        refund.
                      </p>
                      <p>
                        You are required to provide photo evidence that supports
                        the claim (if needed):<br />
                        Transport label, package, products and possible flaws
                        must be clearly visible in the pictures.
                      </p>
                    </b-card-body>
                  </div>
                </div>
                <!-- end ORDER NOTICE -->
              </div>
              <!-- intro -->

              <!-- GRID-AREA: CONTENT -->
              <div class="details-content">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="mb-3">
                      <b-overlay :show="false" rounded="sm">
                        <b-card
                          border-variant="light"
                          header="STEP 1"
                          header-border-variant="secondary"
                          header-bg-variant="white"
                          header-text-variant="dark"
                          align="left"
                          class=""
                        >
                          <b-card-text>
                            <div class="details-content">
                              <p class="font-weight-bold mb-1">
                                Select what's wrong with your shipment.
                              </p>
                              <ClaimCheckbox class="border-bottom mb-3" />
                            </div>
                          </b-card-text>
                          <div class="mb-4">
                            <div class="font-weight-bold mb-1">
                              Detailed description
                            </div>
                            <p>
                              Please provide details about this claim and clarify briefly what the uploaded pictures shows.
                            </p>

                            <!-- <input align-v="center" class="mb-4" accept=".png,.jpeg,.jpg" type="file" @change="prevFile()" multiple> -->

                            <b-form-textarea
                              id="textarea"
                              @input="$store.commit('commentText', commentText)"
                              placeholder="Details..."
                              rows="3"
                              max-rows="6"
                              maxlength="250"
                              v-model="commentText"
                              class="mt-3 mb-2"
                            ></b-form-textarea>
                            <span class="">{{
                              commentText.length + "/250"
                            }}</span>
                            <div
                              v-if="$store.state.claimCategory.lost_transport"
                            >
                              <b>You don't need to provide any photos</b>
                            </div>
                          </div>

                          <div >
                            <b-checkbox
                              :disabled="blockRefund"
                              :switch="true"
                              :value="true"
                              :unchecked-value="false"
                              v-model="refund"
                            >
                              Refund wanted (do not send replacement)
                            </b-checkbox>
                          </div>
                  <p class="text-warning mt-1" style="font-size:0.8rem;" v-show="blockRefund">
                    An order shipped using Tracked Letter cannot be refunded due to lost or damaged package.
                  </p>
                        </b-card>
                      </b-overlay>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <!-- api details-content -->
                    <div class="mb-3">
                      <b-overlay :show="false" rounded="sm">
                        <b-card
                          border-variant="light"
                          header="STEP 2"
                          header-border-variant="secondary"
                          header-bg-variant="white"
                          header-text-variant="dark"
                          align="left"
                          class=""
                        >
                          <b-card-text>
                            <p class="mb-0">
                              <b
                                >Please select/unset quantities to represent
                                claim.</b
                              >
                            </p>
                            <div class="details-content">
                              <ItemClaimOrderContent
                                :items="job.fulldata.items"
                              />
                            </div>
                          </b-card-text>
                        </b-card>
                      </b-overlay>
                    </div>
                  </div>
                </div>
              </div>

              <!-- GRID-AREA: RIGHT SIDE -->
              <div class="details-side">
                <!-- DELIVERY DETAILS -->
                <div class="row">
                  <div class="col-sm-12">
                    <b-overlay
                      :show="hasPendingRequests(['address', 'priority'])"
                      rounded="sm"
                    >
                      <b-card
                        border-variant="light"
                        header="STEP 3"
                        header-border-variant="secondary"
                        header-bg-variant="white"
                        header-text-variant="dark"
                        align="left"
                        class=""
                      >
                        <div>
                          <h5>Upload images</h5>
                          <p>
                            You are required to provide photo evidence that
                            supports the claim (if needed).<br />
                            Transport label, package, products and possible
                            flaws must be clearly visible in the pictures.
                          </p>
                          <!-- <input align-v="center" class="mb-4" accept=".png,.jpeg,.jpg" type="file" @change="prevFile()" multiple> -->
                          <b-form-file
                            multiple
                            v-if="!$store.state.claimCategory.lost_transport"
                            accept=".png,.jpeg,.jpg"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            @change="prevFile()"
                          ></b-form-file>

                          <div v-if="$store.state.claimCategory.lost_transport">
                            <b>You don't need to provide any photos</b>
                          </div>
                        </div>
                        <CanvasRender
                          v-for="(image, index) in $store.state.originalImages"
                          v-bind:key="image.width"
                          v-bind:imageSRC="image"
                          v-bind:indexNumber="index"
                        />
                        <img v-show="false" src="" alt="images" id="cccc" />
                        <transition-group name="list" tag="div">
                          <div
                            v-for="(image, index) in $store.state.images"
                            v-bind:key="image"
                            class="
                              divAlign
                              w-100
                              p-2
                              justify-content-center
                              list-image.src
                            "
                          >
                            <b-img v-bind:src="image" class="imgAlign"></b-img>
                            <b-button
                              variant="danger"
                              class="btnAlign"
                              size="sm"
                              @click="deleteImage(index)"
                              >x</b-button
                            >
                          </div>
                        </transition-group>
                      </b-card>
                    </b-overlay>
                  </div>
                </div>
              </div>

              <div class="details-side mt-3" v-if="false">
                <!-- DELIVERY DETAILS -->

                <div class="row">
                  <div class="col-sm-12">
                    <b-overlay
                      :show="hasPendingRequests(['address', 'priority'])"
                      rounded="sm"
                    >
                      <b-card
                        border-variant="light"
                        header="STEP 4"
                        header-border-variant="secondary"
                        header-bg-variant="white"
                        header-text-variant="dark"
                        align="left"
                        class=""
                      >
                        <div>
                          <h5>Describe the damage</h5>
                          <p>
                            sometext sometext sometext sometext sometext
                            sometext sometext sometext sometext sometext<br />
                            sometext sometext sometext sometext sometext
                            sometext sometext sometext sometext sometext
                          </p>

                          <!-- <input align-v="center" class="mb-4" accept=".png,.jpeg,.jpg" type="file" @change="prevFile()" multiple> -->

                          <b-form-textarea
                            id="textarea"
                            @input="$store.commit('commentText', commentText)"
                            placeholder="Enter something..."
                            rows="3"
                            max-rows="6"
                            maxlength="250"
                            v-model="commentText"
                            class="mt-3 mb-3"
                          ></b-form-textarea>
                          <span class="mt-3">{{
                            commentText.length + "/250"
                          }}</span>
                          <div v-if="$store.state.claimCategory.lost_transport">
                            <b>You don't need to provide any photos</b>
                          </div>
                        </div>
                      </b-card>
                    </b-overlay>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 my-5">
                  <SendClaimButton class="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <pre v-if="false">{{ job }}</pre>
    </div>
    <!-- end details-content -->
  </div>
  <!-- end wrapper -->
</template>


<script>
import { mapGetters, mapActions } from "vuex";

//import JobAddressInline from '@/components/JobAddressInline.vue';
//import OrderContent from '@/components/OrderContent/OrderContent.vue';
//import JobSteps from '@/components/JobSteps/JobSteps.vue';
//import JobContent from '@/components/JobContent/JobContent.vue';
//import ItemOrderContent from '@/components/ItemOrderContent/ItemOrderContent.vue';
//import Chatter from '@/components/Chatter/Chatter.vue';
//import JobExpressSwitch from '@/components/ExpressSwitch/JobExpressSwitch.vue';
//import Shipments from '@/components/Shipments/Shipments.vue';
//import Warnings from '@/components/Warnings/Warnings.vue';
//import Tickets from '@/components/Tickets/Tickets.vue';
//import TicketsSimple from '@/components/Tickets/TicketsSimple.vue';
//import TicketCreate from '@/components/TicketCreate/TicketCreate.vue';
import ClaimCreate from "@/components/ClaimCreate/ClaimCreate.vue";
/*import ItemClaimOrderContent from '@/components/ItemClaimOrderContent/ItemClaimOrderContent.vue';*/
import CanvasRender from "@/components/CanvasRender/CanvasRender.vue";
import SendClaimButton from "@/components/SendClaimButton.vue";
import ClaimCheckbox from "@/components/ClaimCheckbox.vue";
import ItemClaimOrderContent from "@/components/ItemClaimOrderContent/ItemClaimOrderContent.vue";

export default {
  components: {
    //JobAddressInline,
    //JobContent,
    //OrderContent,
    //JobSteps,
    //ItemOrderContent,
    //Chatter,
    //JobExpressSwitch,
    //Shipments,
    //Warnings,
    //Tickets,
    //TicketsSimple,
    //TicketCreate,
    ClaimCreate,
    /* ItemClaimOrderContent,
  //  */
    SendClaimButton,
    ItemClaimOrderContent,
    CanvasRender,
    ClaimCheckbox,
  },

  data() {
    return {
      loading: true, // Page loading
      error: false, // Page Error
      job: null, // Current order (loaded on full)
      refund: false,
      commentText: "",
    };
  },

  computed: {
    ...mapGetters(["hasPendingRequests"]),

    // USER MODULE
    ...mapGetters("user", ["userHasRole", "userHasAnyOfRoles", "currentUser"]),

    // JOB MODULE
    ...mapGetters("job", [
      //'jobStatusName',
    ]),

    // Order ID from _PARAM_
    job_id() {
      return this.$route.params.order_id;
    },
    blockRefund(){
      if(!this.job){
        return false;
      }
      let category = this.$store.state.claimCategory;
        if(category.lost_transport||category.transport_damage){
          let shipment = this.job?.fulldata?.shipping[0];
          if(!shipment){
            return false;
          }
          if((shipment?.service == 'P34'||shipment?.service =='PUA')&&shipment?.speditor == 'PBREV'){
            return true;
          }
        }
      return false;
      //$store.state.claimCategory.lost_transport
    },
    showStatusBox() {
      if (this.job.fulldata.warnings.length > 0) return true;

      if (this.job.tickets.length > 0) return true;

      return false;
    },
  },

  created() {
    this.fetchData();
  },

  watch: {
    refund: function () {
      
      this.$store.commit("importRefund", this.refund);
    },
    blockRefund: function (){
      if(this.blockRefund){
        this.refund = false;
        this.$store.commit("importRefund", this.refund);
      }
    }
  },

  methods: {
    ...mapActions(["addPendingRequest", "removePendingRequest"]),

    // ORDER MODULE
    ...mapActions("job", [
      "getJobPromise",
      "cancelJobPromise",
      "restartJobPromise",
    ]),

    // TICKET MODULE
    ...mapActions("ticket", ["createTicket"]),

    // Save new address in component - when saved result comes back from address component
    saveJobAddress(address) {
      this.job.fulldata.delivery_address = address;
    },
    deleteImage: function (index) {
      this.$store.commit("deleteImages", index);
    },
    prevFile: function () {
      //  const preview = document.querySelector('img');
      const preview = document.getElementById("cccc");
      const files = document.querySelector("input[type=file]").files;
      for (var i = 0; i < files.length; i++) {
        const reader = new FileReader();
        const file = files[i];
        // var images = [];
        reader.addEventListener(
          "load",
          () => {
            // convert image file to base64 string
            preview.src = reader.result;
            var img = new Image();
            img.src = reader.result;
            img.onload = () => {
              this.$store.commit("storeOriginalImages", img);
            };
          },
          false
        );

        if (file) {
          reader.readAsDataURL(file);
        }
      }
    },

    // FETCH CURRENT JOB - FRESH DATA
    fetchData() {
      // Reset all
      this.error = this.job = null;
      this.loading = true;

      this.getJobPromise(this.job_id)
        .then((result) => {
       
          if (result.data.status === "ok") {
            this.job = result.data.job;
          
            
            this.$store.commit("importOrderData", this.job);
            this.loading = false;
          }
          if (result.data.status !== "ok") {
            this.error = result.data.message || "Could not load data!";
            this.$toastIt(this.error, {
              variant: "danger",
              autoHideDelay: 2000,
            });
          }
        })
        .catch((error) => {
          let msg = `Could not retrieve order details! (` + error.message + `)`;
          this.$toastIt(msg, { variant: "danger", autoHideDelay: 2000 });
          this.error = msg;
        });
    },

    // CANCEL current order
    cancelJob() {
      this.$confirmIt(`Are you sure that you want to cancel this job?`).then(
        (value) => {
          if (value) {
            this.cancelJobPromise(this.job_id).then((result) => {
              console.log("CancelAnswer:", result);
              if (result.data.status == "success") {
                this.fetchData();
              }
              if (result.data.status !== "success") {
                const message = result.data.message || "Could not restart job!";
                this.$toastIt(message, {
                  variant: "danger",
                  autoHideDelay: 2000,
                });
              }
            });
          }
        }
      );
    },

    // RESTART current Order
    restartJob() {
      this.$toastIt("Access denied!", {
        variant: "danger",
        autoHideDelay: 2000,
      });
      /*
      this.$confirmIt(`Are you sure that you want to restart this order?`)
      .then(value => {
        if (value) {
          this.restartJobPromise(this.order_id)
          .then((result) => {
            console.log("RestartAnswer:", result);
            this.fetchData();
          });
        }
      });
      */
    },

    // Tickets
    createTicketQuestion() {
      this.$bvModal.show("modal-ticket-create");
    },

    // Claims
    createClaimQuestion() {
      this.$bvModal.show("modal-claim-create");
    },
  },
  mounted(){
  
  }
};
</script>


<style lang="scss">
.alert-danger.style-2 {
  color: #721c24;
  background-color: #f9a6ae;
  border-color: #721c24;
  border-width: 2px;
}
</style>

<style lang="scss" scoped>
// --------------------
// Layout - NEEDS NEW CONTAINER ETC!!!
// --------------------

.divAlign .btnAlign {
  position: absolute;
  top: 2%;
  right: 2%;
}
.divAlign {
  position: relative;
  width: 100%;
}

.divAlign .imgAlign {
  width: 100%;
  height: auto;
}

// Gradient for first and last steps
/*
  .order-steps .step:nth-child(1) .step-icon-wrapper::before {
    background: linear-gradient(90deg, rgba(249,250,252,1) 0%, #e1e7ec 100%);
  }
  .order-steps .step:nth-child(4) .step-icon-wrapper::after {
    background: linear-gradient(270deg, rgba(249,250,252,1) 0%, #e1e7ec 100%);
  }
  .order-steps .step.completed:nth-child(1) .step-icon-wrapper::before {
    background: linear-gradient(90deg, rgba(249,250,252,1) 0%, rgba(0,123,255,1) 100%);
  }
  .order-steps .step.completed:nth-child(4) .step-icon-wrapper::after {
    background: linear-gradient(270deg, rgba(249,250,252,1) 0%, rgba(0,123,255,1) 100%);
  }
  */

// --------------------
// Component Overrides
// --------------------

// Style document content and includes
.root-document {
  padding: 15px;
  background: #fff;
  border: 1px solid #dadada;
  border-radius: 4px;
  margin-bottom: 15px;
}
.root-include {
  padding: 15px;
  background: #fff;
  border: 1px solid #dadada;
  border-radius: 4px;
  margin-bottom: 15px;
}
.sub-includes {
  margin-top: 20px;
  & > div {
    margin-bottom: 10px;
    margin-left: 10px;
  }
  & > div:last-child {
    margin-bottom: 0px;
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(30px);
}
</style>