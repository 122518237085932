<template>
    <b-form-group
        label="Faulty units:"
        label-for="dropdown-1"
        description="Please select damaged/missing quantity for this item."
        class="font-weight-bold"
    >
        <b-form-select id="dropdown-1" v-model="selected_quantity" @change="shout">
            <b-form-select-option :value="0">0</b-form-select-option>
            <b-form-select-option v-for="amount in parseInt(quantity)" v-bind:key="amount" :value="amount">{{amount}}</b-form-select-option>
        </b-form-select>
    </b-form-group>    
</template>

<script>


export default {
    components:{
    },

    data(){
        return{
            selected_quantity: 0,
            value:{
                index:null,
                amount:null
            },
            
        }
    },

    props:{
        quantity:[String,Number],
        index:Number,
      
    },

    mounted(){
        this.selected_quantity = this.quantity;
        this.shout(this.selected_quantity);
    },

    computed:{},

    methods:{
       shout:function(_quantity){
           this.value.index = this.index;
           this.value.amount = _quantity;
           //console.log("selected amount "+ _quantity);
           this.sendValue();
       },
       sendValue:function(){
           this.$store.commit("itemIndex", this.value);
           //console.log(this.$store.state.itemPosition);
       }
    },

    
}
</script>

<style scoped>
    #dropdown-1 {
        max-width: 100px;
    }
</style>
