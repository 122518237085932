<template>
  <div id="sendButton">
    <BaseWarning v-if="checkButton" class="mb-2">
      <b-icon icon="exclamation-triangle-fill" class="text-warning mr-2"></b-icon>
      <b>{{ checkButton }}</b>
    </BaseWarning>

    <b-button
      variant="primary"
      size="lg"
      block
      :disabled="checkButton !== false"
      @click="sendDamagedItems()"
      >Send Claim</b-button
    >
  </div>
</template>

<script>
import claim from "@/apis/Job.js";

export default {
  components: {},
  methods: {
    checkStore: function () {},

    sendDamagedItems: function () {
     
      this.$store.commit("assembleDamagedOrders");
      //  this.$store.commit("deleteClaimData");
      var order = this.$store.state.newOrder;
      //console.log(this.$route.params.order_id, order);

      //   var response =   claim.claim(this.$route.params.order_id,order);
      //console.log(response);
      this.send(order);
      
      this.$store.commit("deleteClaimData");
    },
    send: async function (_order) {
      //console.log(_order);
      var response = await claim.claim(this.$route.params.order_id, _order);

      //console.log(response);
      if ( response.data?.status !== 'ok' ) {
          const message = response.data?.message || 'Unknown Error';
          this.$toastIt(`Error: ${message}`, {variant: 'danger', autoHideDelay: 5000});
          return;
      }

      // OK
      const message = response.data?.message;
      if ( message ) {
          this.$toastIt(message, {variant: 'success', autoHideDelay: 2000});
      }
      this.$router.replace({name: 'individualClaim', params: {claim_id: response.data.claim_id}});
    },
  },

  data() {
    return {
      claimStatus: false,
      imgCheck: this.$store.state.images.length,
      warningText: "You need to upload images",
      disabled: true,
    };
  },
  props: {},
  mounted() {
    //dashboard-dev.printerapi.com
    //console.log("the rout is herer",this.$route.params);
  },
  watch: {
    "$store.state.images.length": function () {
      this.imgCheck = this.$store.state.images.length;
      if (this.imgCheck > 0) {
        this.claimStatus = false;
        console.log("watch image got triggered");
      }
    },
  },
  computed: {
    checkButton: function () {
      var category = this.$store.state.claimCategory;
      if (
        (category.transport_damage ||
          category.defect_packaging ||
          category.wrong_packaging) &&
        this.imgCheck < 1
      ) {
        return "Please select at least one image to continue.";
      }
           /*
               if(!this.$store.state.originalOrder.items[0].line_id){
                //this.claimStatus = true;
                //this.warningText = "no line id in items";
                return true
            }
            */
      //if user has not selected a category
      if (
        !category.transport_damage &&
        !category.defect_packaging &&
        !category.wrong_packaging &&
        !category.lost_transport
      ) {
        //console.log("you need to select a category");
        return "Please select a category to continue.";
      }

      if(!this.$store.state.commentText){
        return 'Please provide a brief description of this claim.'
      }
     
      return false;
    },
  },
};
</script>

<style scoped>
</style>
