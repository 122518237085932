<template>
  <div class="row">
 
      <b-form-group v-slot="{ ariaDescribedby }"   class="mb-3 col-lg m-1 align-self-center">
      <b-form-radio-group
        stacked
        id="radio-group-2"
        v-model="category"
        :aria-describedby="ariaDescribedby"
        name="radio-sub-component"
      >
        <b-form-radio :value="{transport_damage:true,lost_transport:false,defect_packaging:false,wrong_packaging:false}">Damaged during transport</b-form-radio>
        <b-form-radio :value="{transport_damage:false,lost_transport:true,defect_packaging:false,wrong_packaging:false}">Lost during transport</b-form-radio>
        <b-form-radio :value="{transport_damage:false,lost_transport:false,defect_packaging:true,wrong_packaging:false}">Production error / faulty product</b-form-radio>
        <b-form-radio :value="{transport_damage:false,lost_transport:false,defect_packaging:false,wrong_packaging:true}">Wrong content in package</b-form-radio>
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
  export default {
      name:'ClaimCheckbox',
    data() {
      return {
        selected: '',
        category:{transport_damage:false,lost_transport:false,defect_packaging:false,wrong_packaging:false},
        check:false,
        transport_damage:false,
        lost_transport:false,
        defect_packaging:false,
        wrong_packaging:false
      }
    },
    methods:{
        pushToStore(){
            
           
            this.$store.commit("importClaimCategory",this.category);
           /// console.log("the category",this.category);
        }
    },
    watch:{
        category:function(){
            this.pushToStore();

        }
    }
  }
</script>