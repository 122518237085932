<template>

  <b-modal id="modal-claim-create" @show="onShow" size="lg" @close="emitClose" @hidden="resetModal">
    <template #modal-header="{}">
      <h5>Information</h5>
    </template>

    <template #default="{}">
      <b-form v-if="editing">
        <fieldset>
          <div class="mb-2">
            <p>You are about to begin the process of creating a new claim for order <b>{{ job.jobid }}</b>. Claims will be handled by <i>Alfredssons</i> and any 3rd party speditor involved in the case.</p>
            <p>On the following page please specify:<p>
            <ul>
              <li>Reason for claim</li>
              <li>Order items affected</li>
              <li>Photos of verification</li>
            </ul>
            <p class="text-danger d-flex align-items-center mb-3">
              <b-icon icon="exclamation-diamond" font-scale="1.8" class="mr-2"></b-icon>
              Verification photos are required for everything but a package lost in transit.
            </p>
            <p class="mt-4 mb-0 text-center"><b><i>To start the process click Continue.</i></b></p>
          </div>
        </fieldset>
      </b-form>
    </template>

    <template #modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-button size="sm" variant="primary" :to="{name: 'ClaimDetail', params:{order_id: job.id}}">Continue</b-button>
      <b-button size="sm" variant="danger" @click="cancel()">Cancel</b-button>
    </template>

  </b-modal>

</template>


<script>
/**
 * Usage example
 * createTicket() {
 *   this.$bvModal.show('modal-claim-create');
 * }
 */

import { mapGetters } from 'vuex'

export default {
  name: 'ClaimCreate',

  props: [
    'job',
  ],

  data() {
    return {
      editing: false,
      form: {},
      types: [
        { value: 'a', text: 'Production related' },
        { value: 'b', text: 'Shipping related' },
        { value: 'c', text: 'Customer related' },
        { value: 'd', text: 'Nono, not for you related', disabled: true }
      ]
    }
  },

  computed: {
    // USER MODULE
    ...mapGetters('user', [
      'currentUser',
      'userHasRole',
      'users',
      'serviceUsers_ForLists',
    ]),
  },

  methods: {
    onShow() {
      //this.resetFormData();
      this.editing = true;
    },

    // Emit a 'close' when ok is clicked
    emitClose() {
      this.$emit('close'); 
    },

    resetModal() {
      this.editing = false;
    }
  },
  mounted(){
    //console.log(this.job);
    //console.log(this.order_id);
  }
}
</script>