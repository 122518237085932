<template>
    <canvas v-bind:id="'candas'+indexNumber"> 

    </canvas>
</template>


<script>


export default {
    name:'CanvasRender',
    data(){
        return{
            c:null
        }
    },
    props:{
        imageSRC:HTMLImageElement,
        indexNumber:Number
    },
    methods:{
        init:function(){
            var ctx = document.getElementById("candas"+this.indexNumber);
            var c = ctx.getContext("2d");
            this.c = c;
           
            
        },
        renderImage:function(){
           
            var theCanvas = document.getElementById("candas"+this.indexNumber);
            theCanvas.height = this.scaleDownHeight();
            theCanvas.width = this.scaleDownWidth();
            
            this.c.drawImage(this.imageSRC,0,0,theCanvas.width,theCanvas.height);
            var newImageData = theCanvas.toDataURL('image/jpeg',0.5);
            this.$store.commit("storeImage",newImageData);
            this.$store.commit("deleteAfterRender",this.indexNumber);
            
        },
        scaleDownHeight:function(){

    const imageMaxSize = 1280;
    if(this.imageSRC.height<=imageMaxSize&&this.imageSRC.width<=imageMaxSize){
        return this.imageSRC.height
    }

    var imageHeight;
    var imageWidth;
    var ratio;
    if(this.imageSRC.height>this.imageSRC.width){
        imageHeight = imageMaxSize;
         ratio = this.imageSRC.width/this.imageSRC.height;
        imageWidth = ratio*imageHeight;
    }else{
        imageWidth = imageMaxSize;
        ratio = this.imageSRC.height/this.imageSRC.width;
        imageHeight = ratio*imageWidth;
    }
        
         return imageHeight;
},
scaleDownWidth:function(){
    const imageMaxSize = 1280;
    if(this.imageSRC.height<=imageMaxSize&&this.imageSRC.width<=imageMaxSize){
        return this.imageSRC.width;
    }
    var imageHeight;
    var imageWidth;
    var ratio;
    if(this.imageSRC.height>this.imageSRC.width){
        imageHeight = imageMaxSize;
         ratio = this.imageSRC.width/this.imageSRC.height;
        imageWidth = ratio*imageHeight;
    }else{
        imageWidth = imageMaxSize;
        ratio = this.imageSRC.height/this.imageSRC.width;
        imageHeight = ratio*imageWidth;
    }

     
        return imageWidth;  
}

    },
    mounted(){
        this.init();
        this.renderImage();
        console.log("canvas has been created");
    }
}
</script>
